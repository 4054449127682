import React from 'react';

const BuyNow = () => {
  return (
    <div>
	  <div className="text-center">
      <h2 className="maintitle">Buy Now</h2>
	  </div>
      <p className="descriptionbox">Coming soon ... full details for DEXs and Aquo
      </p>
    </div>
  );
};

export default BuyNow;

